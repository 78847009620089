<template>
    <marketing-layout>
        <div class="container-fluid px-0 careers-hero">
            <div class="container-lg">
                <div class="row">
                    <div class="col-12 d-flex flex-column align-items-center text-left text-md-center">
                        <h1 class="text-responsive mb-3">
                            {{ $t('pages.marketing.careers.title') }}
                        </h1>
                        <h5 class="fw-light text-responsive mb-0 px-0 px-lg-6">
                            {{ $t('pages.marketing.careers.subTitle') }}
                        </h5>
                        <div class="d-grid w-100 d-md-block">
                            <a
                                href="https://jobs.ashbyhq.com/Aven"
                                class="btn btn-primary mt-5 px-4"
                            >
                                {{ $t('pages.marketing.careers.positionsTitle') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section-block>
                <div class="container mt-md-4">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-8 g-lg-0">
                            <h2 class="text-responsive">
                                Who is an Aveneer?
                            </h2>
                            <div class="mt-6">
                                <h5>Someone who...</h5>
                                <h5 class="fw-light">
                                    Believes in our mission that low-cost debt makes the world better for people.
                                </h5>
                                <h5 class="fw-light">
                                    Loves working on really hard problems with wicked smart people - while having fun!
                                </h5>
                                <h5 class="fw-light">
                                    Is excited about diversity of ideas and backgrounds - and the creativity it sparks.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>
            <div class="section bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="text-responsive">
                                Why Aven?
                            </h2>
                        </div>
                    </div>
                    <div class="row mt-1 mt-md-3 gy-5">
                        <div class="col-12 col-md-6 mt-2">
                            <img
                                src="@/assets/images/pages/careers/light-bulb-icon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Light bulb"
                            >
                            <p>
                                <strong>Solve Hard Problems</strong><br>
                                We invent new ways to build insanely great consumer financial products by challenging long-held assumptions & solving the hard problems that can positively impact
                                society.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 mt-2">
                            <img
                                src="@/assets/images/pages/careers/payment-icon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Money circle"
                            >
                            <p>
                                <strong>Top Tier Investors</strong><br>
                                Funded by a number of top-tier investors. Founders Fund, Khosla Ventures, SciFi VC, NYCA, Sequoia, Caffeinated Capital.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 mt-2">
                            <img
                                src="@/assets/images/pages/careers/money-bag-icon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Money bag"
                            >
                            <p>
                                <strong>Meaningful Company Equity</strong><br>
                                We believe that employees should have a meaningful stake in the company. Everyone should feel like an owner – because you are.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 mt-2">
                            <img
                                src="@/assets/images/pages/careers/globe-icon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Global"
                            >
                            <p>
                                <strong>Remote Friendly</strong><br>
                                To find the most extraordinary and diverse talent, we have supported remote work across roles. From the SF Bay Area to New York, Los Angeles, Chicago, Canada, Mexico, &
                                Texas.
                            </p>
                        </div>
                    </div>
                    <div class="row mt-6">
                        <h3>Other Benefits</h3>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-3 g-2">
                            <div class="card rounded bg-white border-0 p-2 h-100">
                                <img
                                    src="@/assets/images/pages/careers/medical-icon.svg"
                                    width="32"
                                    height="32"
                                    class="mb-2"
                                    alt="Medical"
                                >
                                <p>
                                    <strong>Medical Insurance</strong><br>
                                    All of our full-time employees get great medical, vision, dental. Coverage for family is available.
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 g-2">
                            <div class="card rounded bg-white border-0 p-2 h-100">
                                <img
                                    src="@/assets/images/pages/careers/calendar-icon.svg"
                                    width="32"
                                    height="32"
                                    class="mb-2"
                                    alt="Calendar"
                                >
                                <p>
                                    <strong>Paid Time Off</strong><br>
                                    In addition to 8 observed holidays, salaried team members have paid time off, vacation, and paid sick leave.
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 g-2">
                            <div class="card rounded bg-white border-0 p-2 h-100">
                                <img
                                    src="@/assets/images/pages/careers/home-heart-icon.svg"
                                    width="32"
                                    height="32"
                                    class="mb-2"
                                    alt="Home Heart"
                                >
                                <p>
                                    <strong>Parental Leave</strong><br>
                                    Being a parent and balancing family time is very important to us. We provide paid parental leave for the parent.
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 g-2">
                            <div class="card rounded bg-white border-0 p-2 h-100">
                                <img
                                    src="@/assets/images/pages/careers/credit-card-icon.svg"
                                    width="32"
                                    height="32"
                                    class="mb-2"
                                    alt="Credit Card"
                                >
                                <p>
                                    <strong>Aven Card</strong><br>
                                    Experience having your own Aven card. Get $100 per month and buy anything you want, on us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section-block>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-8 g-md-0">
                            <h2>Principles</h2>
                            <div class="mt-6">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3 text-secondary">
                                        01
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Customer First
                                        </h5>
                                        <p>We always serve the end consumer first. We start & finish everything with this.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3 text-secondary">
                                        02
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Sustainable
                                        </h5>
                                        <p>Our products must be sustainable for our customers, partners, and ourselves.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3 text-secondary">
                                        03
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Minimalist
                                        </h5>
                                        <p>We are spartan in our products, our design, our code, our process, and our spending. Nothing extra.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3 text-secondary">
                                        04
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Rational
                                        </h5>
                                        <p>We strive to be rational & minimize emotion during decision-making. We prefer to aim, then fire; think then act.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3 text-secondary">
                                        05
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Fast
                                        </h5>
                                        <p>We move quickly & decisively. Speed leads to quality, so we invest in it. We aim to make every day faster than the last.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3 text-secondary">
                                        06
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Confident
                                        </h5>
                                        <p>We value knowing your stuff and expressing it with confidence. We think confidence is a scientific term, not a measure of how loud you are.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3 text-secondary">
                                        07
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Detailed
                                        </h5>
                                        <p>We believe the little things matter. We value diving deep to touch the bare metal of systems, processes, and ideas —That's where invention happens.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>
            <section-block>
                <div class="container">
                    <h1 class="text-responsive">
                        Next Steps...
                    </h1>
                    <div class="d-grid w-100 d-md-block">
                        <a
                            href="https://jobs.ashbyhq.com/Aven"
                            class="btn btn-primary mt-2 px-4"
                        >
                            {{ $t('pages.marketing.careers.positionsTitle') }}
                        </a>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import SectionBlock from '@/components/SectionBlock'

    export default {
        name: 'Careers',
        metaInfo: {
            title: 'Careers',
            meta: [{ name: 'description', vmid: 'description', content: String(i18n.t('pages.marketing.careers.subTitle')) }],
        },
        components: {
            SectionBlock,
            'marketing-layout': Marketing,
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/sectionBlock';
    @import '../../styles/pages/marketing/careers';
</style>
